import {useEffect, useMemo, useState} from "react";

const hasNativeIntersectionObserver = typeof IntersectionObserver !== 'undefined';

export default function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => {

        if (hasNativeIntersectionObserver) {
                return new IntersectionObserver(
                    ([entry]) => setIntersecting(entry.isIntersecting)
                )
        }
        else {
            return null;
        }
    }, [setIntersecting]);

    useEffect(() => {

        if (observer) {
            observer.observe(ref.current)
            // Remove the observer as soon as the component is unmounted
            return () => {
                observer.disconnect()
            }
        }
        else {
            setIntersecting(true);
        }

    }, [ref, observer]);

    return isIntersecting
};
