import "./modal-buttons.scss";

const ModalButtons = (props) => {

    const {children} = props;

    return <div className={"modal-buttons"}>
        {children}
    </div>

}

export default ModalButtons;
