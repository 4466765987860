import React from 'react'
import './assets/box.scss'
import './assets/box-responsive.scss'
import classnames from "classnames";


const Box = (props) => {

    const {children, className, visible = true, collapsed} = props;

    if (!visible) {
        return null;
    }

    return <div className={classnames("box", className, {"collapsed": collapsed})}>
        {children}
    </div>
}

export default Box;
