import React, {useMemo} from "react";

const useChildrenWithProps = (children, props) => useMemo(
    () => React.Children.map(children, c => {
            if (React.isValidElement(c)) {
                return React.cloneElement(c, props);
            }
            return c;
        }
    ),
    [children, props]
);


export {useChildrenWithProps}
