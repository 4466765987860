import {useCallback} from "react"

const queryInputs = 'input:not([ignoresubmit]),select:not([ignoresubmit]), textarea:not([ignoresubmit]),select:not([ignoresubmit])';
const processInputValue = (input, currentData) => {

    if (!input.offsetParent?.className.includes("value-container")) {
        const value = input.value ? input.value : null;
        if (Array.isArray(currentData))
            return [...currentData, value];
        else if (currentData)
            return [currentData, value];
        else
            return value;
    }
}

const handleFormSubmit = (event, fnc, sendEmpty = false) => {
    event.preventDefault();
    fnc(getFormData(event.target, sendEmpty));
}

const getFormData = (target, sendEmpty) => Array.from(target.querySelectorAll(queryInputs))
    .filter((e => (e.id || e.name)))
    .reduce((data, input) => {
        const key = input.id ? input.id : input.name;
        if (input.value || sendEmpty) {
            data[key] = processInputValue(input, data[key]);
        }
        return data;
    }, {});

const useHandleFormSubmit = (onSubmit) => useCallback((formData) => {

    // Is a form Event
    if (formData?.preventDefault) {
        handleFormSubmit(formData, (data) => {
            return onSubmit(data);
        });
    }
    else {
        return onSubmit(formData)
    }

}, [onSubmit]);


export default useHandleFormSubmit;

export {handleFormSubmit, processInputValue, getFormData};
