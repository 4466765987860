export const pt = {

    ALL: "Todos",
    GEOFENCE: "Limitado Geograficamente",
    IN_ENTITY: "Apenas da Entidade",

    YES: "Sim",
    NO: "Não",

    TRUE: "Sim",
    FALSE: "Não",

    ACTIVE: "Ativo",
    INACTIVE: "Inativo",

    VETERINARY: "M. Veterinário",
    NORMAL: "Utilizador Entidade",
    ANY: "Todos",

    ONLY_VETERINARIANS: "Apenas M. Veterinários",

    ANIMAL_REGISTRY: "Registo de Animal",
    ADD: "Adição",
    EDIT: "Edição",

    placeholder: "Selecione uma opção",
    no_results: "Não foram encontrados resultados",
    loading_message: "A pesquisar..",
};

export default pt;
