export const pt = {

    choose_file: "Escolher",

    file_invalid_type_title: "Ficheiro Inválido",
    file_invalid_type: "O Ficheiro {{file}} é de um fomato não suportado!",

    too_many_files_title: "Demasiados Ficheiros",
    too_many_files: "Foram enviados demasiados ficheiros. Por favor reduza o número de ficheiros selecionados",

    file_too_large_title: "Erro no ficheiro",
    file_too_large: "O ficheiro {{file}} é demasiado grande. Por favor reduza o seu tamanho para um valor inferior a 5 Megabytes",

    drop_now: "Largue agora o ficheiro!",
};

export default pt;
