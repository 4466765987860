import React from 'react'
import '../assets/box.scss'
import '../assets/box-responsive.scss'


const BoxTitle = (props) => {

    const { children , className = "" } = props;

    return <div {...props} className={"box-title " + className}>
        {children}
    </div>
}

export default BoxTitle;
