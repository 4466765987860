import {isBoolean} from "./isBoolean";

export const parseBoolean = (value, invalidValue = null) => {
    if (isBoolean(value))
        return value;
    else if (value === 'true')
        return true;
    else if (value === 'false')
        return false
    else return invalidValue;
}
