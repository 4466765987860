const cloneWithoutKey = (obj, keys) => {
    return cloneWithoutKeys(obj,[keys]);
}

const cloneWithoutKeys = (obj, keys) => {

    let cloned = {...obj};

    keys.forEach((key) => {
        delete cloned[key];
    });

    return cloned;

};

const toLowerCaseObject = (obj) => {
    let key, keys = Object.keys(obj);
    let n = keys.length;
    let newObj={}

    while (n--) {
        key = keys[n];
        newObj[key.toLowerCase()] = obj[key];
    }

    return newObj;
}

const objectIsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export default cloneWithoutKeys;

export {cloneWithoutKey, toLowerCaseObject, objectIsEqual}
