export const en = {

    choose_file: "Select a file",

    file_invalid_type_title: "Invalid File Type",
    file_invalid_type: "The filename {{file}} is from an invalid type!",

    too_many_files_title: "Too Many Files",
    too_many_files: "Too Many Files Uploaded. Please upload a reduced number of files",

    file_too_large_title: "File too Large",
    file_too_large: "The file {{file}} should have less than 5 Megabyte!",

    drop_now: "Please drop the file now!",

};

export default en;
