import classnames from "classnames";
import Form from "react-bootstrap/Form";
import React from "react";

const FormControlWithEmptyValue = (props) => {

    const {value, defaultValue, disabled} = props;

    return <>
        {(!value && !defaultValue && disabled) &&
        <div className={"empty-value"}/>
        }
        <Form.Control
            {...props}
            className={classnames({
                "empty-value": (!value && !defaultValue && disabled)
            })}
        />
    </>

}

export default FormControlWithEmptyValue;
