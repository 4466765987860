export const en = {

    ALL: "All",
    GEOFENCE: "Geofence",
    IN_ENTITY: "In Entity",

    YES: "Yes",
    NO: "No",

    TRUE: "Yes",
    FALSE: "No",

    ACTIVE: "Active",
    INACTIVE: "Inactive",

    VETERINARY: "Veterinary",
    NORMAL: "Normal User",
    ANY: "All",

    ONLY_VETERINARIANS: "Only Veterinarians",

    ANIMAL_REGISTRY: "Animal Registry",
    ADD: "Add",
    EDIT: "Edit",


    placeholder: "Please select an option",
    no_results: "No Results Found",
    loading_message: "Searching..",

};

export default en;
