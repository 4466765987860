import {useCallback} from "react";

const useSelectHandlers = (props) => {

    const {labelHandler, classLabel, valueHandler, classValue} = props;

    const handleOptionLabel = useCallback(
        (o) => {
            if (labelHandler) {
                return labelHandler(o);
            }
            return o[classLabel];
        },
        [labelHandler, classLabel]
    );

    const handleOptionValue = useCallback(
        (o) => {
            if (valueHandler) {
                return valueHandler(o);
            } else {
                return o[classValue]
            }
        },
        [valueHandler, classValue]
    );

    return {handleOptionLabel, handleOptionValue}
}

export default useSelectHandlers;
